import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Cv from "../images/cv-Lepee-Laurent-2016.pdf" 

const SecondPage = () => (
  <Layout>
    <SEO title="A propos" />
    <section>
		<h1>A propos</h1>
			<article id="post-16" class="post-16 page type-page status-publish hentry fr-FR">

				<div class="cv">
<div class="motion cv-block">
<span class="cv-rond cv-now"></span><p></p>
<div class="cv-annees">Maintenant – 06/2013</div>
<div class="cv-infos"><span class="cv-infos-title"> Motion4ever </span> <span class="cv-infos-desc"> Web designer </span></div>
</div>
<div class="freelance cv-block">
<span class="cv-rond cv-now"></span><p></p>
<div class="cv-annees">Maintenant – 2011</div>
<div class="cv-infos"><span class="cv-infos-title"> Freelance </span> <span class="cv-infos-desc"> Web designer / développeur web </span></div>
</div>
<div class="bts cv-block">
<span class="cv-rond"></span><p></p>
<div class="cv-annees">2013 – 2011</div>
<div class="cv-infos"><span class="cv-infos-title"> BTS – Services Informatiques aux Organisations </span> <span class="cv-infos-desc"> Option solutions logicielles et applications métiers </span></div>
</div>
<div class="auxiliaire cv-block">
<span class="cv-rond"></span><p></p>
<div class="cv-annees">2011 – 2009</div>
<div class="cv-infos"><span class="cv-infos-title"> Auxiliaire de vie scolaire </span> <span class="cv-infos-desc"> Assistance d’enfants handicapés dans un cadre scolaire, en collaboration avec les enseignants. </span></div>
</div>
<div class="univ cv-block">
<span class="cv-rond"></span><p></p>
<div class="cv-annees">2009-2007</div>
<div class="cv-infos"><span class="cv-infos-title"> Licence 1er et 2ème année – Economie et Gestion </span></div>
</div>
<div class="bac cv-block">
<span class="cv-rond"></span><p></p>
<div class="cv-annees">2007 – 2004</div>
<div class="cv-infos"><span class="cv-infos-title"> Bac STI – Electronique </span></div>
</div>
<div class="born cv-block">
<span class="cv-rond"></span><p></p>
<div class="cv-annees">1988</div>
<div class="cv-infos"><span class="cv-infos-title"> Naissance</span></div>
</div>
</div>
<div class="" style={{textAlign: `center`}}>Télécharger mon CV complet 
<a class="home-link-about" href={Cv} download> <strong>ici</strong></a>
</div>
			</article>
		</section>
  </Layout>
)

export default SecondPage
